import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'


import alphaIcon from '../../assets/images/alpha_icon.png';

import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';

import alphatokenIcon from '../../assets/images/stake.png';
import stakeIcon from '../../assets/images/unstake.png';

import bannerImg from '../../assets/images/alpha-syndicate.jpg';

import { useSelector } from 'react-redux';
import './Home.scss';
import {mintEnabled, setweb3provider} from '../../action/CounterAction';
import reduxStore from '../../ReduxStore';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import Header from '../Header/Header'

const alphaAbi = require('../../helper/alpha.json')

const marketplaceAbi = require('../../helper/marketplace.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function   Home() {
  const address = useSelector(state => state.address);
  const web3provider = useSelector(state => state.web3Provider);

  const [alphaBalance, setAlphaBalance] = React.useState(0)
  const [alphashardBalance, setAlphashardBalance] = React.useState(0)

  const [alphaBalanceRaw, setAlphaBalanceRaw] = React.useState(0)
  const [alphashardBalanceRaw, setAlphashardBalanceRaw] = React.useState(0)

  const marketplaceAddress = "0x663a6BFE7dcE0D0AE843C8487cE17CD4a7Cd3D00"
  const alphaTokenAddress = "0x38f9bb135ea88033f4377B9EA0fb5Cfb773fEc2F"
  const alphashardTokenAddress = "0x492441Bae7CbAA6Ed97f3Db2Ab61F63db06E0b1A"

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  const [Listing, setListing] = React.useState([]);

  useEffect(async() => {
    //Runs only on the first render
    if(web3provider){
    }else{
    }
  }, [web3provider]);

  useEffect(async() => {
    //Runs only on the first render
    if(web3provider){
    }else{
    }
  }, [alphashardBalance]);

  const [selectedImage, setSelectedImage] = React.useState(bannerImg);

  const handleItemClick = (item) => {
    setSelectedImage(item.image); // You may need to adjust this line depending on your data structure
  };

  const loadListing = async() => {
    try{
      setListing([])
      const txLoad = toast.loading('Loading Marketplace');
      const query = `
        {
          collectionCreateds(first: 100) {
            id
            collectionId
            name
            nftAddress
          }
        }
        `;
      const listing = await axios.post('https://api.studio.thegraph.com/query/46966/nftmarketplace-main-3/version/latest', {query: query})

      let listingData = listing.data.data.collectionCreateds;

      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);
      
      const listingPromises = listingData.map(async (listing) => {
        try {
          const listingInfo = await contract.getCollectionListing(listing.collectionId);
      
          const priceInEther1 = ethers.utils.formatEther(listingInfo[2]);
          const priceInEther2 = ethers.utils.formatEther(listingInfo[3]);
          let minHolding = ethers.utils.formatEther(listingInfo[4]);
          minHolding = Math.abs(minHolding);
          listing.name = listingInfo[1]
          listing.priceDisplay1 = parseFloat(priceInEther1).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
          listing.priceDisplay2 = parseFloat(priceInEther2).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
          listing.supply = listingInfo[5].toNumber();
          listing.price1 = listingInfo[2];
          listing.price2 = listingInfo[3];
          listing.minholding = listingInfo[4]; //big number
          if(priceInEther1 <= alphaBalanceRaw && priceInEther2 <= alphashardBalanceRaw){
            console.log("show")
            listing.show = true;
          }
          if(minHolding > alphashardBalanceRaw){
            console.log( "hide",listingInfo[1])
            return null;
          }
          listing.image = "https://marketplace.alphashards.com/images/"+listing.collectionId+".avif"
      
          return listing;
        } catch (error) {
          console.error(`Error processing listing ${listing.collectionId}:`, error);
          return null;
        }
      });

      // Wait for all metadata requests to complete and filter out any null results
      listingData = (await Promise.all(listingPromises)).filter(listing => listing !== null);
      
      listingData.sort((a, b) => {
        if (a.price2.gt(b.price2)) {
          return -1;
        } else if (a.price2.lt(b.price2)) {
          return 1;
        } else {
          return 0;
        }
      });

      console.log(listingData)
      setListing(listingData)
      toast.dismiss(txLoad);
    } catch(err) {
      console.log(err)
    }
}

  const loadToken = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(alphaTokenAddress, alphaAbi, signer);
    const balance = await contract.balanceOf(address);

    console.log(balance)
    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.abs(balanceInEther);
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    
    setAlphaBalanceRaw(roundedBalanceInEther)
    setAlphaBalance(formattedBalance);
  }

  const loadToken2 = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(alphashardTokenAddress, alphaAbi, signer);
    const balance = await contract.balanceOf(address);

    console.log(balance)
    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.abs(balanceInEther); 
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    
    setAlphashardBalanceRaw(roundedBalanceInEther)
    setAlphashardBalance(formattedBalance);
  }


  const buy = async (id,price1,price2) => {

    try{
      console.log(id)
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);
      const tokenContract1 = new ethers.Contract(alphaTokenAddress, alphaAbi, signer);
      const tokenContract2 = new ethers.Contract(alphashardTokenAddress, alphaAbi, signer);

      

      // Check if approval is necessary
      const allowance = await tokenContract1.allowance(address, marketplaceAddress);
      if (allowance.lt(price1)) {
        // Notify user about the approval request
        toast.info('Requesting Approval');
        
        // Approve the Staking Contract to spend the tokens
        const approveTx = await tokenContract1.approve(marketplaceAddress, price1);
        await approveTx.wait();

        // Notify user about the successful approval
        toast.success('Approval Successful');
      }

      const allowance2 = await tokenContract2.allowance(address, marketplaceAddress);
      if (allowance2.lt(price2)) {
        // Notify user about the approval request
        toast.info('Requesting Approval');
        
        // Approve the Staking Contract to spend the tokens
        const approveTx = await tokenContract2.approve(marketplaceAddress, price2);
        await approveTx.wait();

        // Notify user about the successful approval
        toast.success('Approval Successful');
      }



      const tx = await contract.buyNFT(id)
      await tx.wait()
      toast.success('Transaction Success')
      loadListing()
    }catch(err){
      console.log(err.message)
      toast.error(err.message)
    }
  }


  return (
    <>
    <motion.section 
    {...paragraphAnimation}
    id='Home' className='Syndicate'>
      <div className='Syndicate__content'>          
        <div className=''>
          

          <div className='syndicate-banner-container'>
            
                <img src={selectedImage}>
                
                </img>
          </div>
          <div style={{textAlign:'center'}}><h1>ALPHA SYNDICATE</h1></div>

        </div>

        <div className='home-bottom-logo'>
            <img src={alphaIcon}>

            </img>
        </div>
        <footer className="footer">
          <div className="footer__section"> 
            <a href='https://etherscan.io/token/0x38f9bb135ea88033f4377b9ea0fb5cfb773fec2f'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
            <a href='https://discord.com/invite/cGBG3Q9RdB'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
            <a href='https://twitter.com/ALPHAT0KEN'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
            <a href='https://opensea.io/collection/sacred-shard'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
            <a href='https://t.me/+T0KeRpvqgAU5Yjgx'> <img className="footer__icon" src={tg} alt="Icon 1"/></a>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x70235a346a1ec1d7a40181ff88a3a2e5260e1d04'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
            <a href='mailto:alpha.support@gmail.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
          </div>
          <div className="footer__section">
            $Alpha
          </div>
          <div className="footer__section">
            <p className="footer__copyright">© {new Date().getFullYear()} Copyrights & Protected</p>
          </div>
        </footer>
      </div>
    </motion.section>
    </>
  );
}
