import * as React from 'react';
import Syndicate from '../components/Syndicate/Home';
import Layout from '../components/layoutSyndicate';

import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux';

import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import axios from 'axios';
const nftStakingAbi = require('../helper/sacredshardstaking.json')


const MintPage = () => {
  const web3provider = useSelector(state => state.web3Provider);
  const address = useSelector(state => state.address);
  const [sacredShardNft, setSacredShardNft] = React.useState([]);
  const [stakedSacredShardNft, setStakedSacredShardNft] = React.useState([]);
  const [isWhitelisted, setIsWhitelisted] = React.useState(false);

  const nftStakingAddress = process.env.GATSBY_STAKING_NFT_CONTRACT_ADDRESS


  const loadSacredShard = async() => {
    try{
    const query = `
      {
        sacredshardsNfts(first: 500, where: { owner: "${address}" }) {
              id
              owner
              tokenId
          }
      }
      `;
    const sacredShard = await axios.post('https://api.studio.thegraph.com/query/46966/sacredshards-nft-eth/version/latest', {query: query})

    let sacredshardNftData = sacredShard.data.data.sacredshardsNfts;

    setSacredShardNft(sacredshardNftData)

    console.log(sacredshardNftData)

    }catch(err){

    }
  }

  const loadStakedSacredShard = async() => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);

      const stakedNft = await contract.getStakerInfo(address, 0)

      let TokenIdArr = stakedNft[1];

      let stakedShard = []

      TokenIdArr = TokenIdArr.map((token) => {
        let obj = {}
        obj.tokenId = token.toNumber()
        stakedShard.push(obj)
      })
      console.log(TokenIdArr)

      setStakedSacredShardNft(stakedShard)

      console.log(stakedShard)
    }catch(err){

    }
  }

  React.useEffect(() => {
    // Load sacred shard data when the component mounts
    const loadTokenGatedData = async () => {
      try {
        // Check if the user owns Sacred Shards and update the state accordingly
        await loadSacredShard()
        await loadStakedSacredShard()
        console.log('token gated loaded')

        // Check if the user is whitelisted
        const response = await axios.get(`https://api.alphashards.com/api/is-whitelisted/${address}`);
        const { isWhitelisted } = response.data;

        setIsWhitelisted(isWhitelisted);

        
      } catch (error) {
        console.error('Error loading Sacred Shard data:', error);
      }
    };

    if (web3provider) {
      loadTokenGatedData();
    }
  }, [web3provider]);



  return (
    <>
    <Layout>
      {(sacredShardNft.length > 0 || stakedSacredShardNft.length > 0 || isWhitelisted) ? (
        <Syndicate />
      ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ textAlign: 'center', margin: '50px' }}>
              <p>You are not whitelisted.</p>
            </div>
          </div>
      )}
    </Layout> 
    <ToastContainer
      hideProgressBar
      position="bottom-right"
      autoClose={2000}
    />
    </>
  );
};

export default MintPage;


